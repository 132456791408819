<template>
    <div>
        <div class="entry-short" v-bind:style="{ backgroundImage: 'url(' + cmsPageHeaderImage + ')' }">
            <div v-if="venture" class="context">
                <h1>{{ venture.attributes.Name }}</h1>
                <h2>Venture Profile</h2>
            </div>
        </div>

        <div class="breadcrumbs-container">
            <div class="breadcrumbs">
                <router-link to="/">Home</router-link><img class="breadcrumb-arrow" src="~@/assets/arrow_right.svg">
                <router-link to="/start-ups">Start-ups</router-link><img class="breadcrumb-arrow"
                    src="~@/assets/arrow_right.svg">
                <span><strong>{{ venture.attributes.Name }}</strong></span>
            </div>
        </div>

        <section class="component-container" v-if="venture">
            <div class="venture-profile">
                <!--<h2>{{ venture.attributes.Name }}</h2>--->
                <div class="intro">
                    <img class="team-image" loading="lazy" decoding="async" :src="ventureTeamImage"
                        :alt="ventureTeamImageAltTag">
                    <div class="venture-meta">
                        <div class="venture-meta-logos">
                            <img class="venture-logo" loading="lazy" decoding="async" :src="ventureLogo"
                                :alt="ventureLogoAltTag">
                            <div class="social-icons">
                                <a v-if="venture.attributes.website" class="social-icon"
                                    :href="venture.attributes.website" target="_blank">
                                    <img src="@/assets/icons/website.svg" alt="Website">
                                </a>
                                <a v-if="venture.attributes.linkedin" class="social-icon"
                                    :href="venture.attributes.linkedin" target="_blank">
                                    <img src="@/assets/icons/linkedin.svg" alt="linkedin">
                                </a>
                                <a v-if="venture.attributes.instagram" class="social-icon"
                                    :href="venture.attributes.instagram" target="_blank">
                                    <img src="@/assets/icons/instagram.svg" alt="instagram">
                                </a>
                                <router-link v-if="venture.attributes.podcast && venture.attributes.podcast.data"
                                    class="social-icon"
                                    :to="'/podcast/' + venture.attributes.podcast.data.attributes.slug">
                                    <img src="@/assets/icons/apple-podcast.svg" alt="podcast">
                                </router-link>
                            </div>
                        </div>
                        <div class="venture-meta-text">
                            <h3>{{ $t('ventureSubjectTitle') }} {{ venture.attributes.Name }}?</h3>
                            {{ localize(venture.attributes.description) }}
                        </div>
                    </div>
                </div>
                <div class="details">
                    <div class="detail-item team">
                        <h3>{{ $t('ventureTeamTitle') }} {{ venture.attributes.Name }}</h3>
                        <p>
                        <div v-for="teamMember in venture.attributes.teamMembers" :key="teamMember.name">
                            <b>{{ teamMember.name }}</b>,
                            <template v-if="teamMember.position">
                                &nbsp;<i>{{ localize(teamMember.position) }}</i>,
                            </template>
                            {{ localize(teamMember.bio) }}
                        </div>
                        </p>
                    </div>


                    <div v-if="localize(venture.attributes.marketFacts)" class="detail-item market-facts">
                        <h3>{{ $t('ventureMarketFactsTitle') }} {{ venture.attributes.Name }}</h3>
                        <div v-html="md(localize(venture.attributes.marketFacts))"></div>
                    </div>
                    <div v-if="localize(venture.attributes.businessFacts)" class="detail-item business-facts">
                        <h3>{{ $t('ventureBusinessFactsTitle') }} {{ venture.attributes.Name }}</h3>
                        <div v-html="md(localize(venture.attributes.businessFacts))"></div>
                    </div>
                    <div v-if="localize(venture.attributes.targetGroup)" class="detail-item target-group">
                        <h3>{{ $t('ventureTargetGroupTitle') }} {{ venture.attributes.Name }}</h3>
                        <div v-html="md(localize(venture.attributes.targetGroup))"></div>
                    </div>
                    <div v-if="localize(venture.attributes.lookingFor)" class="detail-item looking-for">
                        <h3>{{ $t('ventureLookingForTitle') }} {{ venture.attributes.Name }}?</h3>
                        <div v-html="md(localize(venture.attributes.lookingFor))"></div>
                    </div>

                    <div v-if="localize(venture.attributes.mediaCoverage)" class="detail-item media-coverage">
                        <h3>{{ $t('ventureMediaCoverageTitle') }} {{ venture.attributes.Name }}?</h3>
                        <div v-html="md(localize(venture.attributes.mediaCoverage))"></div>
                    </div>
                    <div v-if="localize(venture.attributes.publications)" class="detail-item publications">
                        <h3>{{ $t('venturePublicationsTitle') }} {{ venture.attributes.Name }}?</h3>
                        <div v-html="md(localize(venture.attributes.publications))"></div>
                    </div>
                    <div v-if="localize(venture.attributes.researchContext)" class="detail-item research-coverage">
                        <h3>{{ $t('ventureResearchContextTitle') }} {{ venture.attributes.Name }}?</h3>
                        <div v-html="md(localize(venture.attributes.researchContext))"></div>
                    </div>
                </div>

            </div>
        </section>

        <PageBuilder v-if="cmsPage" :cmsBlocks="cmsPage.data.attributes.cmsBlocks" />
    </div>
</template>

<script>
import { config } from '@/config'
import { ventureQuery, globalOptionQuery, generalSubContentQuery } from '@/queries'


export default {
    name: 'Venture',

    components: {
        PageBuilder: () => import('@/components/PageBuilder.vue')
    },

    data() {
        return {
            venture: null,
            route: this.$route
        }
    },

    apollo: {
        venture: {
            query: ventureQuery,
            variables() {
                return {
                    slug: this.$route.params.slug
                }
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => data.ventureProfiles.data[0]
        },

        cmsPage: {
            query: generalSubContentQuery,
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => data.generalSubContent
        },

        defaultCmsPageHeader: {
            query: globalOptionQuery,
            variables: {
                optionId: 'default-cms-page-header'
            },
            fetchPolicy: 'cache-first',
            update: data => data.globalOptions.data[0]
        }
    },

    metaInfo() {
        var meta = []

        if (this.metaTitle) {
            meta.push({
                vmid: 'og:title',
                name: 'og:title',
                content: this.metaTitle
            })
        }
        if (this.metaDescription) {
            meta.push({
                vmid: 'description',
                name: 'description',
                content: this.metaDescription
            })
            meta.push({
                vmid: 'og:description',
                name: 'og:description',
                content: this.metaDescription
            })
        }
        if (this.metaImage) {
            meta.push({
                vmid: 'og:image',
                name: 'og:image',
                content: this.metaImage
            })
        }

        return {
            title: this.metaTitle,
            meta: meta
        }
    },

    computed: {

        ventureLogo() {
            if (this.venture && this.venture.attributes.logo.data.attributes.formats) {
                return this.getScaledImageUrl(this.venture.attributes.logo.data.attributes)
            }
            if (this.venture && this.venture.attributes.logo) {
                return this.getImageUrl(this.venture.attributes.logo.data.attributes.url)
            } else if (this.placeholderImage) {
                return this.getImageUrl(this.placeholderImage.url)
            }
        },

        ventureLogoAltTag() {
            if (this.venture && this.venture.attributes.logo) {
                return this.venture.attributes.logo.data.attributes.alternativeText
            } else if (this.placeholderImage) {
                return this.placeholderImage.alternativeText
            }
        },

        ventureTeamImage() {
            if (this.venture && this.venture.attributes.teamImage.data.attributes.formats) {
                return this.getScaledImageUrl(this.venture.attributes.teamImage.data.attributes)
            }
            if (this.venture && this.venture.attributes.teamImage) {
                return this.getImageUrl(this.venture.attributes.teamImage.data.attributes.url)
            } else if (this.placeholderImage) {
                return this.getImageUrl(this.placeholderImage.url)
            }
        },

        ventureTeamImageAltTag() {
            if (this.venture && this.venture.attributes.teamImage) {
                return this.venture.attributes.teamImage.data.attributes.alternativeText
            } else if (this.placeholderImage) {
                return this.placeholderImage.alternativeText
            }
        },

        cmsPageHeaderImage() {
            return this.defaultCmsPageHeader ? this.getImageUrl(this.localize(this.defaultCmsPageHeader.attributes.image).data.attributes.url) : ''
        },

        metaTitle() {
            if (this.venture && this.venture.attributes.Name) {
                return config.globalPageTitle + ' Start-ups - ' + this.venture.attributes.Name
            }

            return config.globalPageTitle + ' - ' + 'Our Ventures'
        },

        metaDescription() {
            if (this.venture && this.localize(this.venture.attributes.shortDescription)) {
                return this.localize(this.venture.attributes.shortDescription)
            }
            return null
        },

        metaImage() {
            if (this.venture && this.venture.attributes.teamImage) {
                return this.getImageUrl(this.venture.attributes.teamImage.data.attributes.url)
            }
            return null
        },
    },

    methods: {}
}
</script>

<style lang="scss" scoped>
.breadcrumbs-container {
    width: 100%;

    .breadcrumbs {
        background-color: white;
        padding: getSpacing('padding-small-vertical', 'desktop') getSpacing('padding-default-horizontal', 'desktop');
        color: black;
        box-sizing: content-box;
        line-height: 16px;
        font-size: 16px;
        max-width: 900px; //$container-width;
        margin: 0 auto;

        @include breakpoint('mobile') {
            //padding-top: 150px;
            padding: getSpacing('padding-small-vertical', 'mobile') getSpacing('padding-default-horizontal', 'mobile');
            line-height: unset;
        }

        a {
            color: #273B41;
            font-size: 16px;
        }

        .breadcrumb-arrow {
            width: 7px !important;
            box-sizing: content-box;
            filter: invert(1);
            vertical-align: middle;
            padding: 0px 10px 3px 10px;
        }
    }
}

.component-container {
    padding: getSpacing('padding-small-vertical', 'desktop') getSpacing('padding-default-horizontal', 'desktop');

    @include breakpoint('tablet') {
        padding: getSpacing('padding-small-vertical', 'tablet') getSpacing('padding-default-horizontal', 'tablet');
    }

    @include breakpoint('mobile') {
        padding: getSpacing('padding-small-vertical', 'mobile') getSpacing('padding-default-horizontal', 'mobile');
    }

    .venture-profile {
        max-width: 900px; //$container-width;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        .intro {

            .team-image {
                width: 100%;
                display: block;
            }

            .venture-meta {
                display: flex;
                flex-direction: row;
                padding: getSpacing('padding-default-vertical', 'desktop') getSpacing('padding-default-horizontal', 'desktop');
                gap: 40px;
                background: $lightGrey;

                @include breakpoint('tablet') {
                    padding: getSpacing('padding-default-vertical', 'tablet') getSpacing('padding-default-horizontal', 'tablet');
                }

                @include breakpoint('mobile') {
                    padding: getSpacing('padding-default-vertical', 'mobile') getSpacing('padding-default-horizontal', 'mobile');
                    flex-direction: column;
                    gap: 0px;
                }

                .venture-meta-logos {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 25%;
                    min-width: 25%;

                    @include breakpoint('mobile') {
                        align-items: center;
                        width: 100%;
                    }

                    .venture-logo {
                        width: 100%;
                        height: auto;

                        @include breakpoint('mobile') {
                            width: 50%;
                        }
                    }

                    .social-icons {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;
                        margin-top: 20px;

                        .social-icon {
                            img {
                                width: 35px;
                                height: 35px;
                            }
                        }

                    }
                }

                .venture-meta-text {
                    width: calc(75% - 40px);
                    min-width: calc(75% - 40px);

                    @include breakpoint('mobile') {
                        width: 100%;
                    }

                    h3 {
                        margin-top: 0px;
                        font-size: getFontSize('defaultText', 'desktop');
                        font-family: ArdelaEdgeX02-Bold;
                        text-align: start;

                        @include breakpoint('mobile') {
                            margin-top: 20px;
                        }
                    }

                    p {
                        text-align: start;
                        font-size: getFontSize('smallText', 'desktop');

                        @include breakpoint('tablet') {
                            font-size: getFontSize('smallText', 'tablet');
                        }

                        @include breakpoint('mobile') {
                            font-size: getFontSize('smallText', 'mobile');
                        }
                    }
                }
            }

            .intro-text {
                text-align: center;

                p {
                    font-size: getFontSize('smallText', 'desktop');
                    text-align: start;

                    @include breakpoint('tablet') {
                        font-size: getFontSize('smallText', 'tablet');
                    }

                    @include breakpoint('mobile') {
                        font-size: getFontSize('smallText', 'mobile');
                    }
                }
            }
        }

        .details {
            .detail-item {
                margin: getSpacing('padding-default-vertical', 'desktop') 0px;
                font-size: getFontSize('smallText', 'desktop');

                h3 {
                    margin-top: 0px;
                    font-size: getFontSize('defaultText', 'desktop');
                    font-family: ArdelaEdgeX02-Bold;
                    text-align: start;

                    @include breakpoint('tablet') {
                        font-size: getFontSize('defaultText', 'tablet');
                    }

                    @include breakpoint('mobile') {
                        font-size: getFontSize('defaultText', 'mobile');
                        margin-top: 20px;
                    }
                }

                ::v-deep p,
                div {
                    text-align: start;
                    font-size: getFontSize('defaultText', 'desktop');

                    @include breakpoint('tablet') {
                        font-size: getFontSize('defaultText', 'tablet');
                    }

                    @include breakpoint('mobile') {
                        font-size: getFontSize('defaultText', 'mobile');
                    }

                    h3 {
                        margin-top: 0px;
                        text-align: start;

                        @include breakpoint('mobile') {
                            margin-top: 20px;
                        }
                    }

                }
            }
        }

    }
}
</style>
